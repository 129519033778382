import React from 'react';
import {useSelector} from "react-redux";

const Welcome = () => {
    const user = useSelector((state: any)=> state.user)
    return (
        <div>
            {user.firstName}
            {user.lastName}
        </div>
    );
};

export default Welcome;