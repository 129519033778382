import React from 'react';
import {useLocation} from "react-router-dom";
import {PageBreadcrumbsComponent} from "../components/Common";

const Analytics = () => {
    const location = useLocation()
    return (
        <div className='w-full'>
            <div className="w-full flex justify-between items-end mb-[70px]">
                <div className='w-full flex flex-col gap-[24px] text-[white]'>
                    <PageBreadcrumbsComponent location={location}/>
                    <h1 className="text-[42px] text-[white] font-[600]">Главная</h1>
                </div>
                <div className=''>

                    {/*    page head buttons    */}

                </div>
            </div>
        </div>
    );
};

export default Analytics;