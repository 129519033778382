import React from "react";

export const FolderIcon = () => {
    return(
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9961 5.48301C8.04259 5.48301 5.52176 7.84251 5.49609 10.983V31.1497C5.49609 33.192 6.3266 35.0327 7.6741 36.3637C8.33043 37.0108 9.10227 37.5462 9.96577 37.911C10.8513 38.2832 11.8321 38.483 12.8294 38.483H16.4961H29.3294C31.2618 38.483 33.0694 37.7405 34.4279 36.4205C35.1576 35.7128 35.9936 33.6302 37.4658 29.3163C37.5831 28.968 40.2359 20.7859 40.2726 20.6649C40.6356 19.486 39.7299 18.3163 38.4961 18.3163C38.2669 18.3163 37.0551 18.3163 36.6628 18.3163V16.483C36.6628 13.4452 34.2006 10.983 31.1628 10.983H27.4961C25.9469 10.983 25.5289 10.6402 24.4601 9.03602C22.7789 6.51518 21.3636 5.48301 18.3294 5.48301C16.7253 5.48301 12.6003 5.48301 10.9961 5.48301ZM10.9961 9.14968C12.6003 9.14968 16.7253 9.14968 18.3294 9.14968C19.8786 9.14968 20.2966 9.4925 21.3654 11.0967C23.0466 13.6175 24.4619 14.6497 27.4961 14.6497H31.1628C32.1748 14.6497 32.9961 15.471 32.9961 16.483V18.3163C26.6363 18.3163 16.9544 18.3163 14.6628 18.3163C13.9038 18.3163 13.2163 18.8095 12.9431 19.519C12.9431 19.519 10.8201 25.0649 9.16276 29.3732V10.983C9.17193 9.92518 10.0061 9.14968 10.9961 9.14968Z" fill="#4962F6"/>
        </svg>
    )
}

export const FileIcon = () => {
    return(
        <svg width="44" height="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#01C38D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#01C38D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}