import React, {useEffect, useState} from 'react';
import {createSearchParams, useLocation, useNavigate} from 'react-router-dom';
import {PageBreadcrumbsComponent} from '../components/Common';
import {Box, FormControl, MenuItem, Pagination, Select, TextField} from '@mui/material';
import {DataGrid} from "@mui/x-data-grid";
import {DeliveriesService} from "../service/DeliveriesService";
import {TransportsService} from "../service/TransportsService";

const tableInitialValues = {
    rows: [],
    status: {
        loading: false,
        error: false,
        message: "",
    },
    filter: {
        search: '',
        start_date: null,
        end_date: null,
        page: 1,
        size: 20,
        total_pages: 1,
    },
}
const Deliveries = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const [table, setTable] = useState<any>({
        ...tableInitialValues,
        columns: [
            {field: 'id', headerName: 'ID'},
            {field: 'date', headerName: 'Дата заказа', flex: 1},
            {field: 'number', headerName: '№ заказа', flex: 1},
            {field: 'date_of_readiness', headerName: 'Дата готовности', flex: 1},
            {field: 'vin', headerName: 'VIN', flex: 1},
            {field: 'manufacturer', headerName: 'Производитель', flex: 1},
            {field: 'equipment', headerName: 'Комплектация', flex: 1},
            {field: 'factory_price', headerName: 'Заводская цена'},
            {field: 'sale_price', headerName: 'Продажная цена на фактуру', flex: 1},
        ],
    })


    const tableList = DeliveriesService.GetDeliveriesList(table.filter)
    useEffect(() => {
        if (tableList.loading) {
            setTable((prevState:any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: true,
                },
            }));
        } else if (tableList.error) {
            setTable((prevState:any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: true,
                },
            }));
        } else {
            const data = tableList.result?.data
            TransportsService.GetTransportsStatusesList().then((res: any)=>{
                setTable((prevState:any) => ({
                    ...prevState,
                    rows: data.results,
                    status: {
                        ...prevState.status,
                        loading: false,
                        error: false,
                    },
                    filter: {
                        ...prevState.filter,
                        page: data.current_page,
                        total_pages: data.total_pages,
                    },
                    columns: [
                        ...prevState.columns,
                        {field: 'status', headerName: 'Статус', renderCell: (params: any)=>
                            <FormControl variant="standard">
                                <Select
                                    defaultValue={params.row.status}
                                    disabled={true}
                                    disableUnderline={true}
                                >
                                    {res.data.map((status: any, index: number) => (
                                        <MenuItem key={index} value={status.id}>
                                            <div title={status.name} dangerouslySetInnerHTML={{__html: status.svg}}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        },
                    ]
                }));
            }).catch(()=>{
                setTable((prevState:any) => ({
                    ...prevState,
                    rows: data.results,
                    status: {
                        ...prevState.status,
                        loading: false,
                        error: false,
                    },
                    filter: {
                        ...prevState.filter,
                        page: data.current_page,
                        total_pages: data.total_pages,
                    },
                }));
            })
        }
    }, [tableList.loading, tableList.error, tableList.result?.data]);
    return (
        <>
            <div className="w-full">
                <div className="w-full flex justify-between items-end mb-[70px]">
                    <div className="w-full flex flex-col gap-[24px] text-[white]">
                        <PageBreadcrumbsComponent location={location} />
                        <h1 className="text-[42px] text-[white] font-[600]">Доставки</h1>
                    </div>
                </div>
                <div className="w-full p-[40px] mb-[40px] bg-white rounded-[4px] shadow-md flex flex-col justify-start items-start gap-[40px]">
                    <Box sx={{ width: '100%' }}>
                        <DataGrid
                            rows={table.rows}
                            columns={table.columns}
                            checkboxSelection={false}
                            keepNonExistentRowsSelected
                            disableColumnFilter
                            disableColumnMenu
                            hideFooter
                            autoHeight
                            loading={table.status.loading}
                            disableRowSelectionOnClick
                            onRowDoubleClick={(params: any)=> navigate({
                                pathname: "/orders/details",
                                search: createSearchParams({
                                    action: 'edit',
                                    order: params.row.id,
                                }).toString()
                            })}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }}
                            sx={{
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: "#464452",
                                    color: "#FFF",
                                }
                            }}
                        />
                    </Box>
                </div>
                <div className='w-full flex gap-[20px] items-center'>
                    <Pagination
                        count={table.filter.total_pages}
                        page={table.filter.page}
                        onChange={(event, value:number)=>{
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    page: value
                                },
                            })
                        }}
                    />
                    <div className='flex items-center gap-[10px]'>
                        <p>Rows:</p>
                        <TextField
                            label=""
                            variant="outlined"
                            size='small'
                            type='number'
                            sx={{width: '100px'}}
                            value={table.filter.size}
                            onChange={(event) => {
                                setTable({
                                    ...table,
                                    filter: {
                                        ...table.filter,
                                        size: event.target.value,
                                    },
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Deliveries;
