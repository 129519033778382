import {createSearchParams} from "react-router-dom";
import React from "react";

export function CreateCleanSearchParams(params: any) {
    const newSearchObj = {...params};
    const keys = Object.keys(newSearchObj);
    for (let i = 0; i < keys.length; i++) {
        if (newSearchObj[keys[i]] === "") {
            delete newSearchObj[keys[i]];
        }
    }
    const emptyOrder = !Object.keys(newSearchObj).length;
    return emptyOrder ? "" : `?${createSearchParams(newSearchObj)}`;
}

export function CustomPageSizeInput({value, onChange}: any) {
    return (
        <label className="tablePageSizeInput">
            <span>Показать: </span>
            <input
                className="outline-none"
                type="text"
                value={value}
                onChange={(e) => {
                    if (onChange) {
                        onChange(e);
                    }
                }}
            />
        </label>
    );
}

export function CheckForPositiveNumbers(value: any) {
    let valueReady = value.replace(/[^0-9]/g, ""); // Удаление всего, кроме цифр
    return valueReady.replace(/^0+/, "");
}

export function ValidatePhoneNumber (input_str: string) {
    // Отфильтруем символы, оставим только цифры и знак "+"
    const filteredValue = input_str.replace(/[^0-9+]/g, '');

    // Если в начале ввода нет знака "+", добавляем его
    return filteredValue.startsWith('+') ? filteredValue : `+${filteredValue}`
}

export function InputCheckForNumbers(value: string) {
    return value === '' || value < '0' ? '0' : value[0] === '0' ? value.slice(1) : value
}

export function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function RemoveEmptyObj(params: any){
    const newSearchObj = {...params}
    const keys = Object.keys(newSearchObj)
    for(let i=0;i < keys.length;i++){
        if(newSearchObj[keys[i]] === ""){
            delete newSearchObj[keys[i]]
        }
    }
    return newSearchObj
}
export function ValidateFormSubmitResponse(response: any[], errorFields: any, messageFields: any) {
    return new Promise((resolve, reject) => {
        const newArray: any[] = Object.entries(response).map(([key, value]) => ({ [key]: value }));
        const errors: any = errorFields;
        const messages: any = messageFields;


        for (let i = 0; i < newArray.length; i++) {
            const errorKey: string = Object.keys(newArray[i])[0];
            const message: string[] = newArray[i][errorKey][0];

            findAndModify(errors, errorKey, true);
            findAndModify(messages, errorKey, message);
        }

        resolve({ errors, messages });
        reject('something is wrong...')
    });
}

function findAndModify(obj:any, targetKey: any, newValue: any) {
    for (const key in obj) {
        if (key === targetKey) {
            obj[key] = newValue;
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            findAndModify(obj[key], targetKey, newValue);
        }
    }
}

export function checkModalResponse(responseData: any, setModal: any, modal: any) {
    ValidateFormSubmitResponse(responseData, modal.validation.error, modal.validation.message)
        .then((res: any) => {
            setModal((prevState: any)=>({
                ...prevState,
                validation: {
                    ...prevState.validation,
                    error: res.errors,
                    message: res.messages
                },
                requested: false
            }))
        }).catch((err) => {
        console.log(err)
    })
}