import {
    Breadcrumbs,
    Button,
    IconButton,
    Link,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import React from "react";
import {FileIcon, FolderIcon} from "./CustomIcons";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import {Link as RouterLink} from "react-router-dom";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export const MainCreateButton = ({onClickEvent}:{onClickEvent: ()=> void}) => {
    return(
        <Button onClick={onClickEvent} variant="contained" color={'green'} sx={{borderRadius: '100px', padding: '16px 24px'}}>
            <AddIcon sx={{color: 'white'}}/>
        </Button>
    )
}
export const MainDeleteButton = ({onClickEvent}:{onClickEvent: ()=> void}) => {
    return(
        <Button onClick={onClickEvent} variant="contained" color={'error'} sx={{borderRadius: '100px', padding: '16px 24px'}}>
            <DeleteIcon sx={{color: 'white'}}/>
        </Button>
    )
}
export const PageBreadcrumbsComponent = ({ location }: { location: any }) => {
    const arrOfRoutes = location.pathname.split('/').filter((route: any) => route !== '');

    // Define a mapping for specific routes
    const breadcrumbMapping: { [key: string]: string } = {
        'home': 'Главная',
        'orders': 'Заказы',
        'details': 'Детали',
        'storage': 'Хранилище',
        'transports': 'Транспорт',
        'deliveries': 'Доставки',
    };

    return (
        <Breadcrumbs maxItems={2} aria-label="breadcrumb" sx={{ color: 'white' }}>
            <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/"
            >
                Home
            </Link>
            {arrOfRoutes.map((route: any, index: number) => (
                index !== arrOfRoutes.length - 1
                    ? (
                        <Link
                            key={index}
                            underline="hover"
                            color="inherit"
                            component={RouterLink}
                            to={`/${arrOfRoutes.slice(0, index + 1).join('/')}`}
                        >
                            {breadcrumbMapping[route] || route}
                        </Link>
                    )
                    : (
                        <Typography key={index} color="inherit">
                            {breadcrumbMapping[route] || route}
                        </Typography>
                    )
            ))}
        </Breadcrumbs>
    );
};
export const StorageItem = ({ item, onMenuItemClick, onDragStart, onDragEnter, onDragOver, onDragLeave, onDrop, isDragged, isTarget }: any) => {
    const colors = {
        main: item.type?.name === 'dir' ?  'text-[#4962F6]' : 'text-[#01C38D]',
        light: item.type?.name === 'dir' ?  'bg-[#4962f61a]' : 'bg-[#01c38d1a]',
        hover: item.type?.name === 'dir' ?  'hover:bg-[#4962f633]' : 'hover:bg-[#01c38d4d]'
    }
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (action: string ) => {
        if(action !== 'close')
            onMenuItemClick(item, action)

        setAnchorEl(null);
    };
    return (
        <div className={`relative py-[10px] px-[30px] rounded-[4px] ${colors.light} ${colors.hover} 
              ${isDragged ? 'opacity-50' : ''} 
              ${isTarget ? 'border-2 border-dashed border-[#4962F6]' : ''}`}
             draggable={true}
             onDragStart={(e) => onDragStart(e)}
             onDragEnter={(e) => onDragEnter(e)}
             onDragOver={(e) => onDragOver(e)}
             onDrop={(e) => onDrop(e)}
             onDragLeave={(e)=> onDragLeave(e)}
             onClick={item.type?.name !== 'file' ? () => handleClose('goTo') : undefined}
        >

            {item.type?.name === 'file'
                ? <FileIcon/>
                : <FolderIcon/>
            }
            <p className={`storageItemsShortenText ${colors.main}`} title={item.name}>
                {item.name}
            </p>

            <div className='absolute right-[6px] top-[6px]' onClick={(e)=>{e.preventDefault(); e.stopPropagation()}}>
                <IconButton
                    sx={{padding: '2px'}}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <MoreVertIcon/>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={()=>handleClose('close')}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={(event)=>{
                        event.stopPropagation()
                        handleClose('delete')
                    }}>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={(event)=>{
                        event.stopPropagation()
                        handleClose('edit')
                    }}>
                        <ListItemIcon>
                            <DriveFileRenameOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Rename</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={(event)=>{
                        event.stopPropagation()
                        handleClose('download')
                    }}>
                        <ListItemIcon>
                            <CloudDownloadIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Download</ListItemText>
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
};