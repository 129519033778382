import React from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

//Администратор - Администратор

export const accessRules: any = {
    "/home": [
        {
            group: 'Администратор',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            }
        },
        {
            group: 'Старший водитель',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            }
        },
        {
            group: 'Водитель',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            }
        },
    ],
    "/orders": [
        {
            group: 'Администратор',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            }
        },
    ],
    "/transports": [
        {
            group: 'Администратор',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            }
        },
        {
            group: 'Старший водитель',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            }
        },
        {
            group: 'Водитель',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            }
        },
    ],
    "/employees": [
        {
            group: 'Администратор',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            }
        },
    ],
    "/deliveries": [
        {
            group: 'Администратор',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            }
        },
        {
            group: 'Старший водитель',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            }
        },
    ],
    "/storage": [
        {
            group: 'Администратор',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            }
        },
    ],
};

const MiddleWare = ({children}: any) => {
    const location = useLocation();
    const user = useSelector((state: any)=> state.user);
    const isOnLoginPage = location.pathname.includes('login');

    if (user.authed) {
        if (isOnLoginPage) {
            return <Navigate to="/home" replace/>; // Redirect logged-in users on the login page to the home page.
        }
        if (location.pathname === '/') {
            return <Navigate to="/home" replace/>;
        }

        // Iterate over the keys (page names) in accessRules.
        for (const pageName in accessRules) {
            if (location.pathname.includes(pageName)) {
                const allowedRoles = accessRules[pageName];
                if (allowedRoles.some((role: any)=>role.group.includes(user.group?.name))) {
                    return children; // Allow access to the page.
                } else {
                    return <Navigate to="/denied-permission" replace/>;
                }
            }
        }

        // If there is no match in accessRules, allow access.
        return children;
    }

    if (!user.authed && !isOnLoginPage) {
        return <Navigate to="/login" replace/>;
    }

    return children;
};

export default MiddleWare;
