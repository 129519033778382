import {CreateCleanSearchParams, RemoveEmptyObj} from "../helpers/helpers";
import {$axios} from "../https/axiosInstance";
import {useAsync} from "react-async-hook";

export const TransportsService = {
    GetTransportsList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/transports/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    async GetTransportsStatusesList() {
        return await $axios.get('/transports/statuses/')
    },
    async GetTransport(transport_id: any) {
        return await $axios.get(`/transports/${transport_id}/`)
    },
    async UpdateTransport(transport: any) {
        return await $axios.put(`/transports/${transport.id}/`, RemoveEmptyObj(transport))
    },
    async CreateTransport(transport: any) {
        return await $axios.post('/transports/', RemoveEmptyObj(transport))
    },
    async DeleteTransport(transport: any) {
        return await $axios.delete(`/transports/${transport.id}/`)
    },
    async DeleteTransportExpense(expense_id: any) {
        return await $axios.delete(`/transports/expense/delete/${expense_id}/`)
    },
}