import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {MainCreateButton, PageBreadcrumbsComponent} from '../components/Common';
import {
    Box,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Select,
    TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from "moment/moment";
import {DataGrid} from "@mui/x-data-grid";
import {EmployeesService} from "../service/EmployeesService";
import {checkModalResponse} from "../helpers/helpers";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

const modalInitialValues = {
    open: false,
    values: {
        id: '',
        username: '',
        group_id: '',
        full_name: '',
        password: '',
    },
    validation: {
        error: {
            username: false,
            group_id: false,
            full_name: false,
            password: false,
            confirm_password: false,
        },
        message: {
            username: '',
            group_id: '',
            full_name: '',
            password: '',
            confirm_password: '',
        }
    },
    requested: false,
    action: '',
    showPassword: false
}
const tableInitialValues = {
    rows: [],
    status: {
        loading: false,
        error: false,
        message: "",
    },
    filter: {
        search: '',
        start_date: null,
        end_date: null,
        page: 1,
        size: 20,
        total_pages: 1,
    },
}
const Storage = () => {
    const location = useLocation();
    const [table, setTable] = useState<any>({
        ...tableInitialValues,
        columns: [
            {field: 'id', headerName: 'ID', width: 120},
            {field: 'full_name', headerName: 'ФИО', flex: 2},
            {field: 'group', headerName: 'Должность', flex: 1, renderCell:(params:any)=>(params.row.group?.name)},
            {field: 'date_joined', headerName: 'Дата', flex: 1, renderCell:(params:any)=>(
                    moment(params.row.date_joined).format('DD.MM.YYYY').toString()
                )},
            {field: 'actions', headerName: 'Действия', width: 120, renderCell: (params: any) => (
                    <div className='flex justify-start gap-2'>
                        <IconButton
                            onClick={()=>{
                                setModal({
                                    ...modal,
                                    open:true,
                                    action: 'edit',
                                    values: {
                                        ...params.row,
                                        group_id: params.row.group?.id
                                    }
                                })
                            }}
                        >
                            <EditIcon/>
                        </IconButton>
                        <IconButton
                            onClick={()=>{setModal({
                                ...modal,
                                open:true,
                                action: 'delete',
                                values: {
                                    ...params.row,
                                    group_id: params.row.group?.id
                                }
                            })}}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </div>
                )},
        ],
    })
    const [modal, setModal] = useState(modalInitialValues)
    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setModal({
            ...modal,
            requested: true
        })

        switch (modal.action) {
            case 'add':
                EmployeesService.CreateEmployee(modal.values).then(()=>{
                    setModal(modalInitialValues)
                    tableList.execute()
                }).catch((err)=>{
                    checkModalResponse(err.response.data, setModal, modal)
                })

                break;

            case 'edit':
                EmployeesService.UpdateEmployee(modal.values).then(()=>{
                    setModal(modalInitialValues)
                    tableList.execute()
                }).catch((err)=>{
                    checkModalResponse(err.response.data, setModal, modal)
                })

                break;

            case 'delete':
                EmployeesService.DeleteEmployee(modal.values).then(()=>{
                    setModal(modalInitialValues)
                    tableList.execute()
                }).catch((err)=>{
                    checkModalResponse(err.response.data, setModal, modal)
                })
                break;

            default:
                break;
        }
    };

    const tableList = EmployeesService.GetEmployeesList(table.filter)
    const groupList = EmployeesService.GetGroupList()

    useEffect(() => {
        if (tableList.loading) {
            setTable((prevState:any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: true,
                },
            }));
        } else if (tableList.error) {
            setTable((prevState:any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: true,
                },
            }));
        } else {
            const data = tableList.result?.data
            setTable((prevState:any) => ({
                ...prevState,
                rows: data.results,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: false,
                },
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
            }));
        }
    }, [tableList.loading, tableList.error, tableList.result?.data]);


    return (
        <>
            <div className="w-full">
                <div className="w-full flex justify-between items-end mb-[70px]">
                    <div className="w-full flex flex-col gap-[24px] text-[white]">
                        <PageBreadcrumbsComponent location={location} />
                        <h1 className="text-[42px] text-[white] font-[600]">Сотрудники</h1>
                    </div>
                    <div className="flex gap-[24px]">
                        {/*    page head buttons    */}
                        <MainCreateButton onClickEvent={() => setModal({ ...modal, open: true, action: 'add' })} />
                    </div>
                </div>
                <div className="w-full p-[40px] mb-[40px] bg-white rounded-[4px] shadow-md flex flex-col justify-start items-start gap-[40px]">
                    <Box sx={{ width: '100%' }}>
                        <DataGrid
                            rows={table.rows}
                            columns={table.columns}
                            checkboxSelection={false}
                            keepNonExistentRowsSelected
                            disableColumnFilter
                            disableColumnMenu
                            hideFooter
                            autoHeight
                            loading={table.status.loading}
                            disableRowSelectionOnClick
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }}
                            sx={{
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: "#464452",
                                    color: "#FFF",
                                }
                            }}
                        />
                    </Box>
                </div>
                <div className='w-full flex gap-[20px] items-center'>
                    <Pagination
                        count={table.filter.total_pages}
                        page={table.filter.page}
                        onChange={(event, value:number)=>{
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    page: value
                                },
                            })
                        }}
                    />
                    <div className='flex items-center gap-[10px]'>
                        <p>Rows:</p>
                        <TextField
                            label=""
                            variant="outlined"
                            size='small'
                            type='number'
                            sx={{width: '100px'}}
                            value={table.filter.size}
                            onChange={(event) => {
                                setTable({
                                    ...table,
                                    filter: {
                                        ...table.filter,
                                        size: event.target.value,
                                    },
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
            <Modal
                open={modal.open}
                onClose={() => setModal(modalInitialValues)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleFormSubmit} className="mainModal relative flex flex-col justify-start items-center">
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                        }}
                        onClick={() => setModal(modalInitialValues)}
                    >
                        <CloseIcon />
                    </IconButton>

                    <h2 className="text-[30px] font-[600] text-[#1E1C2A] mb-[80px]">
                        {modal.action === 'add' && 'Добавить сотрудника'}
                        {modal.action === 'edit' && 'Редактирование сотрудника'}
                        {modal.action === 'delete' && 'Удалить сотрудника?'}
                    </h2>
                    {modal.action !== 'delete' && (
                        <div className="w-full grid grid-cols-2 gap-[30px] mb-[50px]">
                            <TextField
                                fullWidth
                                required
                                label="ФИО"
                                variant="standard"
                                type={'text'}
                                value={modal.values.full_name}
                                error={modal.validation.error.full_name}
                                helperText={modal.validation.message.full_name}
                                onChange={(e)=>{
                                    setModal({
                                        ...modal,
                                        values:{
                                            ...modal.values,
                                            full_name: e.target.value
                                        }
                                    })
                                }}
                            />
                            <TextField
                                fullWidth
                                required
                                label="Логин"
                                variant="standard"
                                type={'text'}
                                value={modal.values.username}
                                error={modal.validation.error.username}
                                helperText={modal.validation.message.username}
                                onChange={(e)=>{
                                    setModal({
                                        ...modal,
                                        values:{
                                            ...modal.values,
                                            username: e.target.value
                                        }
                                    })
                                }}
                            />
                            <FormControl variant="standard">
                                <InputLabel>Должность</InputLabel>
                                <Select
                                    label="Должность"
                                    // required
                                    value={modal.values.group_id}
                                    error={modal.validation.error.group_id}
                                    onChange={(event: any) => {
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                group_id: event.target.value,
                                            },
                                        });
                                    }}
                                >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    {!groupList.loading && !groupList.error &&
                                        groupList.result?.data.map((group: any, index: number) => (
                                            <MenuItem key={index}
                                                      value={group.id}>{group.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                                <FormHelperText>{modal.validation.message.group_id}</FormHelperText>
                            </FormControl>
                            <TextField
                                fullWidth
                                required={modal.action === 'add'}
                                label="Пароль"
                                variant="standard"
                                type={modal.showPassword ? 'text' : 'password'}
                                value={modal.values.password}
                                error={modal.validation.error.password}
                                helperText={modal.validation.message.password}
                                onChange={(e)=>setModal({...modal, values:{...modal.values, password: e.target.value}})}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={()=>setModal({...modal, showPassword: !modal.showPassword})}
                                                onMouseDown={(event)=>event.preventDefault()}
                                            >
                                                {modal.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </div>
                    )}
                    <div className="w-full flex gap-[20px]">
                        <LoadingButton fullWidth variant="outlined" color={modal.action === 'delete' ? 'error' : 'green'} onClick={() => setModal(modalInitialValues)}>
                            Отменить
                        </LoadingButton>
                        <LoadingButton loading={modal.requested} fullWidth variant="contained" color={modal.action === 'delete' ? 'error' : 'green'} type="submit">
                            {modal.action === 'delete' && 'Удалить'}
                            {modal.action === 'edit' && 'Готово'}
                            {modal.action === 'add' && 'Добавить'}
                        </LoadingButton>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default Storage;
