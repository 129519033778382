import React, {useEffect, useState} from 'react';
import {MainCreateButton, StorageItem} from '../components/Common';
import {Breadcrumbs, Button, IconButton, Modal, Skeleton, TextField, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {StorageService} from "../service/StorageService";
import {LoadingButton} from "@mui/lab";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';
import {styled} from '@mui/material/styles';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const modalInitialValues = {
    open: false,
    action: 'add',
    requested: false,
    values: {
        id: '',
        name: '',
    },
    validation: {
        message: {
            name: '',
        },
        error: {
            name: false,
        },
    },
};

const storageInitialValues = {
    filter: {
        page: 1,
        size: 20,
    },
    parent: {
        id: '',
        name: '',
    },
    breadcrumbs: [],
    data: [],
    status: {
        loading: false,
        error: false,
        message: "",
    },
}

const Storage = () => {
    const [storage, setStorage] = useState<any>(storageInitialValues);
    const [modal, setModal] = useState(modalInitialValues);
    const storageList = StorageService.GetStorageList({...storage.filter, parent: storage.parent.id})
    const handleStorageItems = (action: string, item: any) => {
        switch (action) {
            case 'goTo':
                setStorage({
                    ...storage,
                    parent: {
                        ...storage.parent,
                        id: item.id,
                        name: item.name
                    },
                })
                return;

            case 'edit':
                setModal({
                    ...modalInitialValues,
                    open: true,
                    action: 'edit',
                    values: {
                        ...modal.values,
                        id: item.id,
                        name: item.name,
                    },
                });
                return;

            case 'delete':
                setModal({
                    ...modalInitialValues,
                    open: true,
                    action: 'delete',
                    values: {
                        ...modal.values,
                        id: item.id,
                        name: item.name,
                    },
                });
                return;

            case 'download':
                if(item.type.name === 'file'){
                    window.open(item.file, '_new')
                }else{
                    StorageService.DownloadFolder(item.id).then((res: any)=> window.open(res.data.url, '_new'))
                }
                return;

            default:
                return;
        }
    };
    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setModal({
            ...modal,
            requested: true,
        })
        switch (modal.action) {
            case 'add':
                if (modal.values.name) {
                    StorageService.CreateFolder({name: modal.values.name, parent: storage.parent?.id}).then(()=>{
                        storageList.execute()
                    })
                    setModal(modalInitialValues);
                }
                break;

            case 'edit':
                if (modal.values.name) {
                    StorageService.UpdateItem(modal.values).then(()=>{
                        storageList.execute()
                    })
                    setModal(modalInitialValues);
                }
                break;

            case 'delete':
                StorageService.DeleteItem(modal.values).then(()=>{
                    storageList.execute()
                })
                setModal(modalInitialValues);
                break;

            default:
                break;
        }
    };
    useEffect(() => {
        if (storageList.loading) {
            setStorage((prevState:any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: true,
                },
            }));
        } else if (storageList.error) {
            setStorage((prevState:any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: true,
                },
            }));
        } else {
            const data = storageList.result?.data
            setStorage((prevState:any) => ({
                ...prevState,
                breadcrumbs: data.breadcrumbs !== null ? data.breadcrumbs : [],
                data: data.results,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: false,
                },
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
            }));
        }
    }, [storageList.loading, storageList.error, storageList.result?.data]);

    const [fileUploadInfo, setFileUploadInfo] = useState({
        progress: 0,
        showProgress: false,
    })
    const handleAddFiles = (event: any) => {
        const files = event.target.files;
        if (files.length === 0) {
            return;
        }

        setFileUploadInfo({
            ...fileUploadInfo,
            showProgress: true,
        });

        const formData = new FormData();
        if( storage.parent.id !== ''){
            formData.append('parent', storage.parent.id);
        }

        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        const config = {
            onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;

                // Calculate the upload progress percentage
                const progress = Math.round((loaded / total) * 100);

                // Update the state to reflect the upload progress
                setFileUploadInfo({
                    ...fileUploadInfo,
                    progress: progress,
                });
            },
        };

        StorageService.AddFiles(formData, config)
            .then((res) => {
                setFileUploadInfo({
                    ...fileUploadInfo,
                    showProgress: false,
                });
                storageList.execute()
            })
            .catch((error) => {
                console.error('Error uploading files:', error);
                setFileUploadInfo({
                    ...fileUploadInfo,
                    showProgress: false,
                });
            });
    };

    // Inside the Storage component
    const [draggedItem, setDraggedItem] = useState<any>(null);
    const [targetFolder, setTargetFolder] = useState<any>(null);

    const handleDragStart = (event: any, item:any) => {
        setDraggedItem(item);
    };

    const handleDragEnter = (event: any, folder:any) => {
        // Highlight the potential drop target
        setTargetFolder(folder);
    };
    const handleDragLeave = (event: any) => {
        // Highlight the potential drop target
        event.preventDefault();
        setTargetFolder(null);
    };

    const handleDragOver = (event: any) => {
        // Prevent default to allow drop
        event.preventDefault();
    };

    const handleDrop = (event: React.DragEvent, targetItem: any) => {
        event.preventDefault();

        if (draggedItem && targetItem && targetItem.type?.name === 'dir') {
            // Move the item (You need to implement this based on your storage service)
            StorageService.MoveItem({
                items: [draggedItem.id],
                ...targetItem.id !== '' ? {destination : targetItem.id} : null
            })
                .then(() => {
                    storageList.execute();
                })
                .catch((error) => {
                    console.error('Error moving item:', error);
                });

            // Reset state after drop
            setDraggedItem(null);
            setTargetFolder(null);
        }
    };

    return (
        <>
            <div className="w-full">
                <div className="w-full flex justify-between items-end mb-[70px]">
                    <div className="w-full flex flex-col gap-[24px] text-[white]">
                        {/*<PageBreadcrumbsComponent location={location} />*/}
                        <h1 className="text-[42px] text-[white] font-[600]">Хранилище</h1>
                    </div>
                    <div className="flex gap-[24px]">
                        {/*    page head buttons    */}
                        <Button component="label" variant="contained" sx={{borderRadius: '100px', padding: '16px 24px'}}>
                            {fileUploadInfo.showProgress
                                ? <CircularProgress size={24} sx={{color: 'white'}} variant="determinate" value={fileUploadInfo.progress} />
                                : <CloudUploadIcon />
                            }
                            <VisuallyHiddenInput multiple disabled={fileUploadInfo.showProgress} onChange={handleAddFiles} type="file" />
                        </Button>
                        <MainCreateButton onClickEvent={() => setModal({ ...modal, open: true, action: 'add' })} />
                    </div>
                </div>
                <div className="w-full p-[40px] bg-white rounded-[4px] shadow-xl flex flex-col justify-start items-start gap-[40px]"
                     onDrop={(e) => handleDrop(e, storage.parent)}
                     onDragOver={(e) => handleDragOver(e)}
                >
                    <Breadcrumbs maxItems={4} aria-label="breadcrumb" sx={{ color: '#1E1C2A' }} separator={<NavigateNextIcon fontSize="small" />}>
                        <Button
                            color="inherit"
                            onClick={()=>{
                                setStorage({
                                    ...storage,
                                    parent: {
                                        id: '',
                                        name: '',
                                    },
                                })
                            }}
                            onDragEnter={(e:any) => handleDragEnter(e, {id: '', name: '', type: {id: '', name: 'dir'}})}
                            onDragOver={(e:any) => handleDragOver(e)}
                            onDrop={(e:any) => handleDrop(e, {id: '', name: '', type: {id: '', name: 'dir'}})}
                            sx={{width: targetFolder?.id === '' ? '200px' : 'unset'}}
                        >
                            Главная
                        </Button>
                        {storage.breadcrumbs.map((route: any, index: number) => (
                            index !== storage.breadcrumbs.length - 1
                                ? (
                                    <Button
                                        key={index}
                                        color="inherit"
                                        onClick={()=>{
                                            setStorage({
                                                ...storage,
                                                parent: {
                                                    id: route.id,
                                                    name: route.name,
                                                },
                                            })
                                        }}
                                        onDragEnter={(e:any) => handleDragEnter(e, route)}
                                        onDragOver={(e:any) => handleDragOver(e)}
                                        onDrop={(e:any) => handleDrop(e, route)}
                                        sx={{width: targetFolder === route ? '200px' : 'unset'}}
                                    >
                                        {route.name}
                                    </Button>
                                )
                                : (
                                    <Typography key={index} color="inherit">
                                        {route.name}
                                    </Typography>
                                )
                        ))}
                    </Breadcrumbs>
                    {/*<h3 className="text-[26px] text-[#1E1C2A] font-[600]">{storage.parent.name}</h3>*/}
                    <div className="w-full flex gap-[30px] flex-wrap">
                        {storage.status.loading
                            ? storage.data.length === 0
                                ?
                                    <>
                                        <Skeleton variant="rounded" width={109} height={120} />
                                        <Skeleton variant="rounded" width={109} height={120} />
                                        <Skeleton variant="rounded" width={109} height={120} />
                                        <Skeleton variant="rounded" width={109} height={120} />
                                        <Skeleton variant="rounded" width={109} height={120} />
                                        <Skeleton variant="rounded" width={109} height={120} />
                                        <Skeleton variant="rounded" width={109} height={120} />
                                        <Skeleton variant="rounded" width={109} height={120} />
                                    </>
                                :
                                    storage.data.map((item: any, index: number) => (
                                        <StorageItem
                                            onMenuItemClick={(item:any, action:any) => handleStorageItems(action, item)}
                                            key={index}
                                            item={item}
                                            onDragStart={(e:any) => handleDragStart(e, item)}
                                            onDragEnter={(e:any) => handleDragEnter(e, item)}
                                            onDragOver={(e:any) => handleDragOver(e)}
                                            onDragLeave={(e:any) => handleDragLeave(e)}
                                            onDrop={(e:any) => handleDrop(e, item)}
                                            isDragged={draggedItem === item}
                                            isTarget={targetFolder === item}
                                        />
                                    ))
                            : storage.status.error
                                ? 'Error...'
                                : storage.data.length === 0
                                    ? 'No data...'
                                    :
                                        storage.data.map((item: any, index: number) => (
                                            <StorageItem
                                                onMenuItemClick={(item:any, action:any) => handleStorageItems(action, item)}
                                                key={index}
                                                item={item}
                                                onDragStart={(e:any) => handleDragStart(e, item)}
                                                onDragEnter={(e:any) => handleDragEnter(e, item)}
                                                onDragOver={(e:any) => handleDragOver(e)}
                                                onDragLeave={(e:any) => handleDragLeave(e)}
                                                onDrop={(e:any) => handleDrop(e, item)}
                                                isDragged={draggedItem === item}
                                                isTarget={targetFolder === item}
                                            />
                                        ))
                        }
                        {/* eslint-disable-next-line react/jsx-no-undef */}
                    </div>
                </div>
            </div>
            <Modal
                open={modal.open}
                onClose={() => setModal(modalInitialValues)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleFormSubmit} className="mainModal relative flex flex-col justify-start items-center">
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                        }}
                        onClick={() => setModal(modalInitialValues)}
                    >
                        <CloseIcon />
                    </IconButton>

                    <h2 className="text-[30px] font-[600] text-[#1E1C2A] mb-[80px]">
                        {modal.action === 'add' && 'Добавить папку'}
                        {modal.action === 'edit' && 'Редактирование папки'}
                        {modal.action === 'delete' && 'Удалить папку?'}
                    </h2>
                    <div className="w-full grid items-center gap-[30px] mb-[50px]">
                        {modal.action !== 'delete' && (
                            <TextField
                                fullWidth
                                required
                                label="Название папки"
                                variant="standard"
                                type={'text'}
                                value={modal.values.name}
                                error={modal.validation.error.name}
                                helperText={modal.validation.message.name}
                                onChange={(e) => setModal({ ...modal, values: { ...modal.values, name: e.target.value } })}
                            />
                        )}
                    </div>
                    <div className="w-full flex gap-[20px]">
                        <LoadingButton fullWidth variant="outlined" color={modal.action === 'delete' ? 'error' : 'green'} onClick={() => setModal(modalInitialValues)}>
                            Отменить
                        </LoadingButton>
                        <LoadingButton loading={modal.requested} fullWidth variant="contained" color={modal.action === 'delete' ? 'error' : 'green'} type="submit">
                            {modal.action === 'delete' && 'Удалить'}
                            {modal.action === 'edit' && 'Готово'}
                            {modal.action === 'add' && 'Добавить'}
                        </LoadingButton>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default Storage;
