import React from "react";
import {Outlet} from "react-router";
import Header from "./Header";
import {motion as m} from "framer-motion";

const Layout = () => {
    return (
        <m.section
            className="w-full min-h-screen px-[20px] pt-[180px] pb-[55px] text-[#282828] relative bg-white flex flex-col items-center justify-start"
            initial={{scale: 0.9}}
            animate={{scale: 1}}
            transition={{duration: 0.3, ease: "easeOut"}}>
            <Header/>
            <div className='z-[999] w-full h-full max-w-[1248px]'>
                <Outlet/>
            </div>
            <div className='w-full absolute top-0 left-0 h-[429px] bg-[#1E1C2A] z-0'></div>
        </m.section>
    );
};

export default Layout;