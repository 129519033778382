import React, {useEffect, useState} from 'react';
import {createSearchParams, useLocation, useNavigate} from 'react-router-dom';
import {MainDeleteButton, PageBreadcrumbsComponent} from '../components/Common';
import {Button, IconButton, Modal, TextField} from "@mui/material";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import CloseIcon from "@mui/icons-material/Close";
import {LoadingButton} from "@mui/lab";
import {OrdersService} from "../service/OrdersService";
import dayjs from "dayjs";
import {checkModalResponse} from "../helpers/helpers";
import moment from "moment/moment";

const formInitialValues = {
    values:{
        model: '',
        year: '',
        contract_number: '',
        total_expenses: '',
        equipment: '',
        seller_company_name: '',
        km_before_inspection: '',
        vin: '',
        color: '',
        insurance: '',
        before_tkm: '',
        purchase_price: '',
        after_tkm: '',
        registration: '',
        date: null,
        numbers: '',
        deposit: '',
        invoice_price: '',
        registration_plate_number: '',
        sale_price: '',
        transport_declaration_number: '',
        transport_declaration: '',
        address: '',
        date_of_production: null,
        tax_per_month: '',
    },
    validation:{
        message:{
            model: '',
            year: '',
            contract_number: '',
            total_expenses: '',
            equipment: '',
            seller_company_name: '',
            km_before_inspection: '',
            vin: '',
            color: '',
            insurance: '',
            before_tkm: '',
            purchase_price: '',
            after_tkm: '',
            registration: '',
            date: '',
            numbers: '',
            deposit: '',
            invoice_price: '',
            registration_plate_number: '',
            sale_price: '',
            transport_declaration_number: '',
            transport_declaration: '',
            address: '',
            date_of_production: '',
            tax_per_month: '',
        },
        error:{
            model: false,
            year: false,
            contract_number: false,
            total_expenses: false,
            equipment: false,
            seller_company_name: false,
            km_before_inspection: false,
            vin: false,
            color: false,
            insurance: false,
            before_tkm: false,
            purchase_price: false,
            after_tkm: false,
            registration: false,
            date: false,
            numbers: false,
            deposit: false,
            invoice_price: false,
            registration_plate_number: false,
            sale_price: false,
            transport_declaration_number: false,
            transport_declaration: false,
            address: false,
            date_of_production: false,
            tax_per_month: false,
        }
    },
    requested: false,
    open: false,
}
const OrdersDetails = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const action = params.get('action');
    const order = params.get('order');
    const [form, setForm] = useState<any>({
        ...formInitialValues,
        action: action
    })

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        setForm({
            ...form,
            requested: true,
        })
        switch (form.action) {
            case 'add':
                OrdersService.CreateOrder(form.values).then((res)=>{
                    navigate({
                        pathname: "/orders/details",
                        search: createSearchParams({
                            action: 'edit',
                            order: res.data.id
                        }).toString()
                    })
                    window.location.reload()
                }).catch((err: any)=>{
                    checkModalResponse(err.response.data, setForm, form)
                })

                break;

            case 'edit':
                OrdersService.UpdateOrder(form.values).then(()=>{
                    window.location.reload()
                }).catch((err: any)=>{
                    checkModalResponse(err.response.data, setForm, form)
                })

                break;

            case 'delete':
                OrdersService.DeleteOrder(form.values).then((res)=>{
                    navigate('/orders')
                }).catch((err: any)=>{
                    checkModalResponse(err.response.data, setForm, form)
                })

                break;

            default:
                break;
        }
    };

    useEffect(()=>{
        if(order !== null && action === 'edit'){
            OrdersService.GetOrder(order).then((res: any)=>{
                setForm({
                    ...form,
                    values:{
                        ...form.values,
                        ...res.data,
                        date: dayjs(res.data.date),
                        date_of_production: dayjs(res.data.date_of_production),
                    }
                })
            })
        }
    },[])
    return (
        <>
            <div className="w-full">
                <div className="w-full flex justify-between items-end mb-[70px]">
                    <div className="w-full flex flex-col gap-[24px] text-[white]">
                        <PageBreadcrumbsComponent location={location} />
                        <h1 className="text-[42px] text-[white] font-[600]">Заказ</h1>
                    </div>
                    <div className="flex gap-[24px]">
                        {/*    page head buttons    */}
                        {action === 'edit' &&
                            <MainDeleteButton onClickEvent={() => {setForm({...form, open:true, action: 'delete'})}} />
                        }
                    </div>
                </div>
                <div className="w-full p-[40px] mb-[40px] bg-white rounded-[4px] shadow-md flex flex-col justify-start items-start gap-[40px]">
                    <form onSubmit={(e)=>{e.preventDefault(); setForm({...form, open:true})}} className="w-full grid grid-cols-3 gap-x-[108px] gap-y-[30px]">
                        <TextField
                            fullWidth
                            label="Марка"
                            variant="standard"
                            type={'text'}
                            value={form.values.model}
                            error={form.validation.error.model}
                            helperText={form.validation.message.model}
                            onChange={(e)=>setForm({...form, values:{...form.values, model: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="Год"
                            variant="standard"
                            type={'text'}
                            value={form.values.year}
                            error={form.validation.error.year}
                            helperText={form.validation.message.year}
                            onChange={(e)=>setForm({...form, values:{...form.values, year: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="Средство / № Договора"
                            variant="standard"
                            type={'text'}
                            value={form.values.contract_number}
                            error={form.validation.error.contract_number}
                            helperText={form.validation.message.contract_number}
                            onChange={(e)=>setForm({...form, values:{...form.values, contract_number: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="Все затраты на ТС"
                            variant="standard"
                            type={'number'}
                            value={form.values.total_expenses}
                            error={form.validation.error.total_expenses}
                            helperText={form.validation.message.total_expenses}
                            onChange={(e)=>setForm({...form, values:{...form.values, total_expenses: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="Комплектация"
                            variant="standard"
                            type={'text'}
                            value={form.values.equipment}
                            error={form.validation.error.equipment}
                            helperText={form.validation.message.equipment}
                            onChange={(e)=>setForm({...form, values:{...form.values, equipment: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="Название фирмы от продавца"
                            variant="standard"
                            type={'text'}
                            value={form.values.seller_company_name}
                            error={form.validation.error.seller_company_name}
                            helperText={form.validation.message.seller_company_name}
                            onChange={(e)=>setForm({...form, values:{...form.values, seller_company_name: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="Км до осмотра"
                            variant="standard"
                            type={'number'}
                            value={form.values.km_before_inspection}
                            error={form.validation.error.km_before_inspection}
                            helperText={form.validation.message.km_before_inspection}
                            onChange={(e)=>setForm({...form, values:{...form.values, km_before_inspection: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="VIN"
                            variant="standard"
                            type={'text'}
                            value={form.values.vin}
                            error={form.validation.error.vin}
                            helperText={form.validation.message.vin}
                            onChange={(e)=>setForm({...form, values:{...form.values, vin: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="Цвет"
                            variant="standard"
                            type={'text'}
                            value={form.values.color}
                            error={form.validation.error.color}
                            helperText={form.validation.message.color}
                            onChange={(e)=>setForm({...form, values:{...form.values, color: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="Страховка"
                            variant="standard"
                            type={'text'}
                            value={form.values.insurance}
                            error={form.validation.error.insurance}
                            helperText={form.validation.message.insurance}
                            onChange={(e)=>setForm({...form, values:{...form.values, insurance: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="До ТКМ"
                            variant="standard"
                            type={'text'}
                            value={form.values.before_tkm}
                            error={form.validation.error.before_tkm}
                            helperText={form.validation.message.before_tkm}
                            onChange={(e)=>setForm({...form, values:{...form.values, before_tkm: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="Закупочная цена"
                            variant="standard"
                            type={'number'}
                            value={form.values.purchase_price}
                            error={form.validation.error.purchase_price}
                            helperText={form.validation.message.purchase_price}
                            onChange={(e)=>setForm({...form, values:{...form.values, purchase_price: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="После ТКМ"
                            variant="standard"
                            type={'text'}
                            value={form.values.after_tkm}
                            error={form.validation.error.after_tkm}
                            helperText={form.validation.message.after_tkm}
                            onChange={(e)=>setForm({...form, values:{...form.values, after_tkm: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="Поставка на учет"
                            variant="standard"
                            type={'text'}
                            value={form.values.registration}
                            error={form.validation.error.registration}
                            helperText={form.validation.message.registration}
                            onChange={(e)=>setForm({...form, values:{...form.values, registration: e.target.value}})}
                        />
                        <DatePicker
                            label="Дата"
                            format="YYYY-MM-DD"
                            slotProps={{ textField: { variant: "standard", error: form.validation.error.date, helperText: form.validation.message.date } }}
                            value={form.values.date}
                            onChange={(e)=>setForm({...form, values:{...form.values, date: moment(e?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD')}})}
                        />
                        <TextField
                            fullWidth
                            label="Номера"
                            variant="standard"
                            type={'text'}
                            value={form.values.numbers}
                            error={form.validation.error.numbers}
                            helperText={form.validation.message.numbers}
                            onChange={(e)=>setForm({...form, values:{...form.values, numbers: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="Задаток -19%"
                            variant="standard"
                            type={'number'}
                            value={form.values.deposit}
                            error={form.validation.error.deposit}
                            helperText={form.validation.message.deposit}
                            onChange={(e)=>setForm({...form, values:{...form.values, deposit: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="Сумма к фактуре № Инвойса"
                            variant="standard"
                            type={'number'}
                            value={form.values.invoice_price}
                            error={form.validation.error.invoice_price}
                            helperText={form.validation.message.invoice_price}
                            onChange={(e)=>setForm({...form, values:{...form.values, invoice_price: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="Госномер"
                            variant="standard"
                            type={'text'}
                            value={form.values.registration_plate_number}
                            error={form.validation.error.registration_plate_number}
                            helperText={form.validation.message.registration_plate_number}
                            onChange={(e)=>setForm({...form, values:{...form.values, registration_plate_number: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="Продажная цена на фактуре"
                            variant="standard"
                            type={'number'}
                            value={form.values.sale_price}
                            error={form.validation.error.sale_price}
                            helperText={form.validation.message.sale_price}
                            onChange={(e)=>setForm({...form, values:{...form.values, sale_price: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="№ Т. декларации"
                            variant="standard"
                            type={'text'}
                            value={form.values.transport_declaration_number}
                            error={form.validation.error.transport_declaration_number}
                            helperText={form.validation.message.transport_declaration_number}
                            onChange={(e)=>setForm({...form, values:{...form.values, transport_declaration_number: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="Т. декларация"
                            variant="standard"
                            type={'text'}
                            value={form.values.transport_declaration}
                            error={form.validation.error.transport_declaration}
                            helperText={form.validation.message.transport_declaration}
                            onChange={(e)=>setForm({...form, values:{...form.values, transport_declaration: e.target.value}})}
                        />
                        <TextField
                            fullWidth
                            label="Адрес"
                            variant="standard"
                            type={'text'}
                            value={form.values.address}
                            error={form.validation.error.address}
                            helperText={form.validation.message.address}
                            onChange={(e)=>setForm({...form, values:{...form.values, address: e.target.value}})}
                        />
                        <DatePicker
                            label="Дата продукции"
                            format="YYYY-MM-DD"
                            slotProps={{ textField: { variant: "standard", error: form.validation.error.date_of_production, helperText: form.validation.message.date_of_production } }}
                            value={form.values.date_of_production}
                            onChange={(e)=>setForm({...form, values:{...form.values, date_of_production: moment(e?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD')}})}
                        />
                        <TextField
                            fullWidth
                            label="Налог за 30 дней"
                            variant="standard"
                            type={'number'}
                            value={form.values.tax_per_month}
                            error={form.validation.error.tax_per_month}
                            helperText={form.validation.message.tax_per_month}
                            onChange={(e)=>setForm({...form, values:{...form.values, tax_per_month: e.target.value}})}
                        />

                        <div className='w-full col-start-1 col-end-4 flex justify-center items-center gap-[20px]'>
                            <Button sx={{width: '200px'}} variant="contained" color={'green'} type="submit">
                                {action === 'add' && 'Добавить'}
                                {action === 'edit' && 'Редактировать'}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
            <Modal
                open={form.open}
                onClose={() => setForm({...form, open: false, action: action})}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleFormSubmit} className="mainModal relative flex flex-col justify-start items-center">
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                        }}
                        onClick={() => setForm({...form, open: false, action: action})}
                    >
                        <CloseIcon />
                    </IconButton>

                    <h2 className="text-[30px] font-[600] text-[#1E1C2A] mb-[80px]">
                        {form.action === 'add' && 'Добавить заказ'}
                        {form.action === 'edit' && 'Редактирование заказ'}
                        {form.action === 'delete' && 'Удалить заказ?'}
                    </h2>

                    <div className="w-full flex gap-[20px]">
                        <LoadingButton fullWidth variant="outlined" color={form.action === 'delete' ? 'error' : 'green'} onClick={() => setForm(formInitialValues)}>
                            Отменить
                        </LoadingButton>
                        <LoadingButton loading={form.requested} fullWidth variant="contained" color={form.action === 'delete' ? 'error' : 'green'} type="submit">
                            {form.action === 'delete' && 'Удалить'}
                            {form.action === 'edit' && 'Готово'}
                            {form.action === 'add' && 'Добавить'}
                        </LoadingButton>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default OrdersDetails;
