import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import MiddleWare from "./components/MiddleWare";
import Layout from "./components/Layout";
import Auth from "./pages/Auth";
import {useSelector} from "react-redux";
import Welcome from "./pages/Welcome";
import Analytics from "./pages/Analytics";
import Storage from "./pages/Storage";
import Employees from "./pages/Employees";
import Orders from "./pages/Orders";
import OrdersDetails from "./pages/OrdersDetails";
import Transports from "./pages/Transports";
import TransportsDetails from "./pages/TransportsDetails";
import Deliveries from "./pages/Deliveries";


function App() {
    const userPosition = useSelector((state:any) => state.user?.group?.name)
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="login"
                    element={
                        <MiddleWare>
                            <Auth/>
                        </MiddleWare>
                    }
                />
                <Route
                    path="/*"
                    element={
                        <MiddleWare>
                            <Layout/>
                        </MiddleWare>
                    }
                >
                    <Route index path='home' element={
                        userPosition === 'admin'
                            ? <Analytics/>
                            : <Welcome/>
                    }/>
                    <Route path='storage' element={<Storage/>}/>
                    <Route path='employees' element={<Employees/>}/>
                    <Route path='deliveries' element={<Deliveries/>}/>
                    <Route path='orders' element={<Orders/>}/>
                    <Route path='orders/details' element={<OrdersDetails/>}/>
                    <Route path='transports' element={<Transports/>}/>
                    <Route path='transports/details' element={<TransportsDetails/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
