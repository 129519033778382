import {CreateCleanSearchParams, RemoveEmptyObj} from "../helpers/helpers";
import {$axios} from "../https/axiosInstance";
import {useAsync} from "react-async-hook";

export const EmployeesService = {
    GetEmployeesList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/accounts/staff/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    GetGroupList() {
        return useAsync(async () => {
            return await $axios.get('/accounts/groups/')
        }, [])
    },
    async UpdateEmployee(staff: any) {
        return await $axios.put(`/accounts/staff/${staff.id}/`, RemoveEmptyObj(staff))
    },
    async CreateEmployee(staff: any) {
        return await $axios.post('/accounts/staff/', RemoveEmptyObj(staff))
    },
    async DeleteEmployee(staff: any) {
        return await $axios.delete(`/accounts/staff/${staff.id}/`)
    },
}