import {CreateCleanSearchParams, RemoveEmptyObj} from "../helpers/helpers";
import {$axios} from "../https/axiosInstance";
import {useAsync} from "react-async-hook";

export const StorageService = {
    GetStorageList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/storage/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    async UpdateItem(item: any) {
        return await $axios.put(`/storage/update/${item.id}/`, RemoveEmptyObj({name: item.name}))
    },
    async CreateFolder(folder: any) {
        return await $axios.post('/folder/create/', RemoveEmptyObj(folder))
    },
    async DeleteItem(item: any) {
        return await $axios.delete(`/storage/delete/${item.id}/`)
    },
    async AddFiles(formData: any, config: any) {
        return await $axios.post('/file/create/', formData, config);
    },
    async MoveItem(data: any) {
        return await $axios.post('/move/', data);
    },
    async DownloadFolder(storage_id: string) {
        return await $axios.post('/storage/download/', {storage_id: storage_id});
    }
}