import React, {useState} from "react";
import HomeIcon from '@mui/icons-material/Home';
import {accessRules} from "./MiddleWare";
import {
    AppBar,
    Avatar,
    Box,
    Button, Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    Tooltip,
    Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleIcon from '@mui/icons-material/People';
import HistoryIcon from '@mui/icons-material/History';
import StorageIcon from '@mui/icons-material/Storage';
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import LogoutIcon from '@mui/icons-material/Logout';
import {logout} from "../store/slices/userSlice";


const mobileHeaderHeight = '70px'
function generateMenuItems(userPosition: string) {
    const menuItems = [
        {
            path: "/home",
            label: "Главная",
            icon: <HomeIcon />,
        },
        {
            path: "/orders",
            label: "Заказы",
            icon: <ShoppingCartIcon />, // Suggested Material Icon for Orders
        },
        {
            path: "/transports",
            label: "Транспорты",
            icon: <LocalShippingIcon />, // Suggested Material Icon for Transport
        },
        {
            path: "/employees",
            label: "Сотрудники",
            icon: <PeopleIcon />, // Suggested Material Icon for Employees
        },
        {
            path: "/deliveries",
            label: "Доставки",
            icon: <HistoryIcon />, // Suggested Material Icon for Delivery History
        },
        {
            path: "/storage",
            label: "Хранилище",
            icon: <StorageIcon />, // Suggested Material Icon for Storage
        },
    ];
    const rules = accessRules

    return menuItems.filter((item: any) => Array.from(new Set(rules[item.path])).some((elem: any)=> {
        return elem.group === userPosition
    }));
}

function Header() {
    const location = useLocation()

    const user = useSelector((state: any) => state.user)
    const dispatch = useDispatch()

    const menuItems = generateMenuItems(user.group.name);
    const [navMenu, setNavMenu] = useState(false);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleNavMenuToggle = () => {
        setNavMenu(!navMenu);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        dispatch(logout())
    }
    return (
        <AppBar position="fixed"
                sx={{
                    backgroundColor: '#1E1C2A',
                    zIndex: '9999',
                    width: '100%',
                    padding: {xs: 'unset', md: '52px 0 12px 0'},
                    height: {xs: mobileHeaderHeight, md: 'unset'},
                }}>
            <div className='w-full flex items-center max-w-[1248px] px-[20px] m-auto'>
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/home"
                    sx={{
                        mr: 2,
                        display: { xs: "none", md: "flex" },
                        fontFamily: "monospace",
                        fontWeight: 700,
                        letterSpacing: ".3rem",
                        color: "inherit",
                        textDecoration: "none"
                    }}
                >
                    LOGO
                </Typography>

                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleNavMenuToggle}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        variant="temporary"
                        open={navMenu}
                        onClose={handleNavMenuToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'flex', md: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240, top: mobileHeaderHeight },
                        }}
                    >
                        <List>
                            {menuItems.map((item, index) => (
                                <Link to={item.path} key={index}>
                                    <ListItem key={index} disablePadding>
                                        <ListItemButton>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.label} />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>
                            ))}
                        </List>
                    </Drawer>
                </Box>

                <Typography
                    variant="h5"
                    noWrap
                    component="a"
                    href="/home"
                    sx={{
                        mr: 2,
                        display: { xs: "flex", md: "none" },
                        flexGrow: 1,
                        fontFamily: "monospace",
                        fontWeight: 700,
                        letterSpacing: ".3rem",
                        color: "inherit",
                        textDecoration: "none"
                    }}
                >
                    LOGO
                </Typography>

                <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, justifyContent: 'center', gap:'24px' }}>
                    {menuItems.map((item, index) => (
                        <Link to={item.path} key={index}>
                            <Button
                                onClick={handleNavMenuToggle}
                                sx={{
                                    color: "white",
                                    display: "flex",
                                    borderRadius: '100px',
                                    gap: '20px',
                                    whiteSpace: 'nowrap',
                                    cursor: 'pointer',
                                    ...location.pathname.includes(item.path) ? {
                                        background: 'rgba(180, 178, 192, 0.20)',
                                        paddingX: '24px',
                                    } : {},
                                    '&:hover': {
                                        background: "rgba(180, 178, 192, 0.20)",
                                    }
                                }}
                            >
                                {location.pathname.includes(item.path) && item.icon}
                                {item.label}
                            </Button>
                        </Link>
                    ))}
                </Box>

                <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt={`${user.full_name}`} src="/static/images/avatar/2.jpg" />
                        </IconButton>
                    </Tooltip>
                    <Popover
                        open={Boolean(anchorElUser)}
                        anchorEl={anchorElUser}
                        onClose={handleCloseUserMenu}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        sx={{
                            top: mobileHeaderHeight,
                        }}
                    >
                        <div className='w-full flex items-center gap-[10px] p-[10px]'>
                            <Avatar alt={`${user.full_name}`} src="/static/images/avatar/2.jpg" />
                            <div className='whitespace-nowrap flex flex-col'>
                                <p className='text-[14px] font-[400]'>{user.full_name}</p>
                                <p className='text-[12px] font-[400] text-[#01C38D]'>{user.group?.name}</p>
                            </div>
                        </div>
                        <Divider/>
                        <ListItem sx={{p: 0}}>
                            <ListItemButton onClick={handleLogout}>
                                <LogoutIcon sx={{mr: '10px'}}/> Logout
                            </ListItemButton>
                        </ListItem>
                    </Popover>
                </Box>
            </div>
        </AppBar>
    );
}

export default Header;
