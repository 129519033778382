import React, {useEffect, useState} from 'react';
import {createSearchParams, useLocation, useNavigate} from 'react-router-dom';
import {MainCreateButton, PageBreadcrumbsComponent} from '../components/Common';
import {Box, FormControl, MenuItem, Pagination, Select, TextField} from '@mui/material';
import {DataGrid} from "@mui/x-data-grid";
import {OrdersService} from "../service/OrdersService";

const tableInitialValues = {
    rows: [],
    status: {
        loading: false,
        error: false,
        message: "",
    },
    filter: {
        search: '',
        start_date: null,
        end_date: null,
        page: 1,
        size: 20,
        total_pages: 1,
    },
}
const Orders = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const [table, setTable] = useState<any>({
        ...tableInitialValues,
        columns: [
            {field: 'id', headerName: 'ID'},
            {field: 'model', headerName: 'Марка', flex: 1},
            {field: 'vin', headerName: 'VIN', flex: 1},
            {field: 'after_tkm', headerName: 'После ТКМ', flex: 1},
            {field: 'color', headerName: 'Цвет'},
            {field: 'year', headerName: 'Год'},
            {field: 'sale_price', headerName: 'Продажная цена на фактуре', flex: 1},
            {field: 'seller_company_name', headerName: 'Название фирмы от продавца', flex: 1},
            {field: 'address', headerName: 'Адрес', flex: 1},
        ],
    })


    const tableList = OrdersService.GetOrdersList(table.filter)
    useEffect(() => {
        if (tableList.loading) {
            setTable((prevState:any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: true,
                },
            }));
        } else if (tableList.error) {
            setTable((prevState:any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: true,
                },
            }));
        } else {
            const data = tableList.result?.data
            OrdersService.GetOrdersStatusesList().then((res: any)=>{
                setTable((prevState:any) => ({
                    ...prevState,
                    rows: data.results,
                    status: {
                        ...prevState.status,
                        loading: false,
                        error: false,
                    },
                    filter: {
                        ...prevState.filter,
                        page: data.current_page,
                        total_pages: data.total_pages,
                    },
                    columns: [
                        ...prevState.columns,
                        {field: 'status', headerName: 'Статус', renderCell: (params: any)=>
                            <FormControl variant="standard">
                                <Select
                                    defaultValue={params.row.status}
                                    onChange={(event: any) => {
                                        OrdersService.UpdateOrder({
                                            ...params.row,
                                            status: event.target.value
                                        }).then((res: any)=>{
                                            navigate({
                                                pathname: "/transports/details",
                                                search: createSearchParams({
                                                    action: 'edit',
                                                    order: res.data.id
                                                }).toString()
                                            })
                                        })
                                    }}
                                    disableUnderline={true}
                                >
                                    {res.data.map((status: any, index: number) => (
                                        <MenuItem key={index} value={status.id}>
                                            <div title={status.name} dangerouslySetInnerHTML={{__html: status.svg}}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        },
                    ]
                }));
            }).catch(()=>{
                setTable((prevState:any) => ({
                    ...prevState,
                    rows: data.results,
                    status: {
                        ...prevState.status,
                        loading: false,
                        error: false,
                    },
                    filter: {
                        ...prevState.filter,
                        page: data.current_page,
                        total_pages: data.total_pages,
                    },
                }));
            })
        }
    }, [tableList.loading, tableList.error, tableList.result?.data]);
    return (
        <>
            <div className="w-full">
                <div className="w-full flex justify-between items-end mb-[70px]">
                    <div className="w-full flex flex-col gap-[24px] text-[white]">
                        <PageBreadcrumbsComponent location={location} />
                        <h1 className="text-[42px] text-[white] font-[600]">Заказы</h1>
                    </div>
                    <div className="flex gap-[24px]">
                        {/*    page head buttons    */}
                        <MainCreateButton onClickEvent={() => navigate({
                            pathname: "/orders/details",
                            search: createSearchParams({
                                action: 'add'
                            }).toString()
                        })} />
                    </div>
                </div>
                <div className="w-full p-[40px] mb-[40px] bg-white rounded-[4px] shadow-md flex flex-col justify-start items-start gap-[40px]">
                    <Box sx={{ width: '100%' }}>
                        <DataGrid
                            rows={table.rows}
                            columns={table.columns}
                            checkboxSelection={false}
                            keepNonExistentRowsSelected
                            disableColumnFilter
                            disableColumnMenu
                            hideFooter
                            autoHeight
                            loading={table.status.loading}
                            disableRowSelectionOnClick
                            onRowDoubleClick={(params: any)=> navigate({
                                pathname: "/orders/details",
                                search: createSearchParams({
                                    action: 'edit',
                                    order: params.row.id,
                                }).toString()
                            })}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }}
                            sx={{
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: "#464452",
                                    color: "#FFF",
                                }
                            }}
                        />
                    </Box>
                </div>
                <div className='w-full flex gap-[20px] items-center'>
                    <Pagination
                        count={table.filter.total_pages}
                        page={table.filter.page}
                        onChange={(event, value:number)=>{
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    page: value
                                },
                            })
                        }}
                    />
                    <div className='flex items-center gap-[10px]'>
                        <p>Rows:</p>
                        <TextField
                            label=""
                            variant="outlined"
                            size='small'
                            type='number'
                            sx={{width: '100px'}}
                            value={table.filter.size}
                            onChange={(event) => {
                                setTable({
                                    ...table,
                                    filter: {
                                        ...table.filter,
                                        size: event.target.value,
                                    },
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Orders;
