import {CreateCleanSearchParams, RemoveEmptyObj} from "../helpers/helpers";
import {$axios} from "../https/axiosInstance";
import {useAsync} from "react-async-hook";

export const OrdersService = {
    GetOrdersList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/orders/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    async GetOrdersStatusesList() {
        return await $axios.get('/orders/statuses/')
    },
    async GetOrder(order_id: any) {
        return await $axios.get(`/orders/${order_id}/`)
    },
    async UpdateOrder(order: any) {
        return await $axios.put(`/orders/${order.id}/`, RemoveEmptyObj(order))
    },
    async CreateOrder(order: any) {
        return await $axios.post('/orders/', RemoveEmptyObj(order))
    },
    async DeleteOrder(order: any) {
        return await $axios.delete(`/orders/${order.id}/`)
    },
}