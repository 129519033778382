import React, {FormEvent, useState} from 'react';
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import {login} from "../store/slices/userSlice";
import {AuthService} from "../service/AuthService";
import {jwtDecode} from "jwt-decode";
import {setCookie} from "typescript-cookie";
import {access_token_name, refresh_token_name} from "../https/axiosInstance";
import {checkModalResponse} from "../helpers/helpers";
import LoadingButton from '@mui/lab/LoadingButton';

const formInitialState = {
    values:{
        username: '',
        password: '',
    },
    validation:{
        message:{
            username: '',
            password: '',
        },
        error:{
            username: false,
            password: false,
        }
    },
    showPassword: false,
    requested: false,
}

const Auth = () => {
    const dispatch = useDispatch()
    const [form, setForm] = useState(formInitialState)
    const submitForm = (e: FormEvent) => {
        e.preventDefault()
        setForm({
            ...form,
            requested: true,
        })

        AuthService.GetToken(form.values).then((res)=>{
            // Get the current time in seconds
            const currentTimeInSeconds = Math.floor(Date.now() / 1000);

            const accessDecode: any = jwtDecode(res.data.access)
            const refreshDecode: any = jwtDecode(res.data.refresh)

            const accessExpirationInSeconds = accessDecode.exp;
            const refreshExpirationInSeconds = refreshDecode.exp;

            // Calculate the difference in seconds
            const accessDifferenceInSeconds = accessExpirationInSeconds - currentTimeInSeconds;
            const refreshDifferenceInSeconds = refreshExpirationInSeconds - currentTimeInSeconds;

            // Convert the difference in seconds to days
            const accessDifferenceInDays = accessDifferenceInSeconds / (60 * 60 * 24);
            const refreshDifferenceInDays = refreshDifferenceInSeconds / (60 * 60 * 24);

            setCookie(access_token_name, res.data.access, {expires: accessDifferenceInDays})
            setCookie(refresh_token_name, res.data.refresh, {expires: refreshDifferenceInDays})

            // write a user

            dispatch(login({
                authed: true,
                full_name: form.values.username,
                group: res.data?.group,
            }))

        }).catch((err)=>{
            checkModalResponse(err.response.data, setForm, form)
        })
    }
    return (
        <div className='w-full h-full min-h-screen bg-[#464452] flex justify-center items-center'>
            <form onSubmit={submitForm} className='min-w-[580px] py-[90px] px-[130px] rounded-[10px] bg-[#FFF] flex flex-col items-center gap-[40px]'>
                <h1 className='text-[42px] font-[600] text-black mb-[40px]'>
                    Авторизация
                </h1>
                <div className='w-full flex flex-col items-center gap-[20px]'>
                    <TextField
                        fullWidth
                        required
                        label="Логин"
                        variant="standard"
                        type={'text'}
                        value={form.values.username}
                        error={form.validation.error.username}
                        helperText={form.validation.message.username}
                        onChange={(e)=>setForm({...form, values:{...form.values, username: e.target.value}})}
                    />
                    <TextField
                        fullWidth
                        required
                        label="Пароль"
                        variant="standard"
                        type={form.showPassword ? 'text' : 'password'}
                        value={form.values.password}
                        error={form.validation.error.password}
                        helperText={form.validation.message.password}
                        onChange={(e)=>setForm({...form, values:{...form.values, password: e.target.value}})}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>setForm({...form, showPassword: !form.showPassword})}
                                        onMouseDown={(event)=>event.preventDefault()}
                                    >
                                        {form.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </div>
                <LoadingButton
                    fullWidth
                    variant="contained"
                    color='green'
                    loading={form.requested}
                    type={"submit"}>
                    Войти
                </LoadingButton>
            </form>
        </div>
    );
};

export default Auth;