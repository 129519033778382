import {createTheme, outlinedInputClasses} from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        green: Palette['primary'];
    }

    interface PaletteOptions {
        green?: PaletteOptions['primary'];
    }
}

// Update the Button's color options to include an ochre option
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        green: true;
    }
}

export const theme = createTheme({
    palette: {
        green: {
            main: '#01C38D',
            light: 'rgba(1,195,141,0.69)',
            dark: '#02946c',
            contrastText: '#FFF',
        },
    },
    typography: {
        // "fontFamily": `"Futura"`,
    },
    components:{
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    textTransform: 'none',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '--TextField-brandBorderColor': '#B4B2C0',
                    '--TextField-brandBorderHoverColor': '#B2BAC2',
                    '--TextField-brandBorderFocusedColor': '#01C38D',
                    '& label.Mui-focused': {
                        color: 'var(--TextField-brandBorderFocusedColor)',
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '--TextField-brandBorderColor': '#B4B2C0',
                    '--TextField-brandBorderHoverColor': '#B2BAC2',
                    '--TextField-brandBorderFocusedColor': '#01C38D',
                    '& label.Mui-focused': {
                        color: 'var(--TextField-brandBorderFocusedColor)',
                    },
                },
            },
        },
        // MuiOutlinedInput: {
        //     styleOverrides: {
        //         notchedOutline: {
        //             borderColor: 'var(--TextField-brandBorderColor)',
        //         },
        //         root: {
        //             [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
        //                 borderColor: 'var(--TextField-brandBorderHoverColor)',
        //             },
        //             [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
        //                 borderColor: 'var(--TextField-brandBorderFocusedColor)',
        //             },
        //         },
        //     },
        // },
        // MuiFilledInput: {
        //     styleOverrides: {
        //         root: {
        //             '&:before, &:after': {
        //                 borderBottom: '2px solid var(--TextField-brandBorderColor)',
        //             },
        //             '&:hover:not(.Mui-disabled, .Mui-error):before': {
        //                 borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
        //             },
        //             '&.Mui-focused:after': {
        //                 borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
        //             },
        //         },
        //     },
        // },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&:before': {
                        borderBottom: '2px solid var(--TextField-brandBorderColor)',
                    },
                    '&:hover:not(.Mui-disabled, .Mui-error):before': {
                        borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
                    },
                    '&.Mui-focused:after': {
                        borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
                    },
                },
            },
        },
    }
});