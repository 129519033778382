import {CreateCleanSearchParams} from "../helpers/helpers";
import {$axios} from "../https/axiosInstance";
import {useAsync} from "react-async-hook";

export const DeliveriesService = {
    GetDeliveriesList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/deliveries/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
}